body {
  height: 100%;
  overflow: auto;
  background: #1a1a22;
  color: #fff;
  font-family: "Circular", "Circular Std", "Roboto", sans-serif;
}


/* Gray background */
.shade1 {
  background-color: #f8f9fa;
}

.shade2 {
  background-color: #e9ecef;
}

.shade3 {
  background-color: #dee2e6;
}

.up-grey-1 {
  background-color: #1a1a22;
}

.up-grey-2 {
  background-color: #34333b;
}

.up-blue-1 {
  background-color: rgb(36, 36, 48);
}

.up-orange-1 {
  background-color: rgb(255, 122, 100);
}

.text-up-orange {
  --text-opacity: 1;
  color: rgba(255, 122, 100, var(--text-opacity)) !important;
}

.text-up-grey {
  --text-opacity: 1;
  color: #d2d2d2;
  color: rgba(210, 210, 210, var(--text-opacity));
}

.opacity-100 {
  opacity: 1 !important;
}

.opacity-75 {
  opacity: .75 !important;
}

.opacity-50 {
  opacity: .5 !important;
}

.opacity-25 {
  opacity: .25 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.up-btn {
  background-color: rgb(255, 122, 100);
  border-radius: 3px;
  color: rgb(36, 36, 48);
  display: block;
  font-size: 0.8rem;
  font-weight: 900;
  padding: 0.5rem 1.5rem;
  position: relative;
  cursor: pointer;
}

.up-btn:hover {
  background-color: rgb(62, 83, 84);
  color: rgb(252, 240, 127);
}